import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { AppLocationService } from '@aca-new/app/shared/services/browser-services/app-location/app-location-service';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { ENVIRONMENT } from '@aca-new/environments/environment';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

declare let ENV_TYPE: 'dev' | 'pp' | 'prod';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppAuthenticationService {
  public readonly currentUserSubject$: Subject<IUser> = new Subject<IUser>();
  private _isLogout = false;

  public constructor(
    private readonly _appLocationService: AppLocationService,
    private readonly _storageService: StorageService,
    private readonly _httpService: HttpService,
    private readonly _overlayService: AppOverlayService,
    @Inject(WINDOW) private readonly _window: Window
  ) {}

  public updateUserData(data: Readonly<IUser>): void {
    this.currentUserSubject$.next(data);
  }

  public navigateToLogin(): void {
    if (this._isLogout) {
      this.logout();

      return;
    }

    const acaLoginUrl = `/login?redirect=${encodeURIComponent(`${location.origin}/login?token={token}&userId={userId}&sessionId={sessionId}`)}`;

    location.assign(this._appLocationService.getAcaUrl() + acaLoginUrl + encodeURIComponent(`&redirectUrl=${location.pathname + location.search}`));
  }

  public clearAuthenticationData(): void {
    this._storageService.clearAuthenticationData();
  }

  public logout(): void {
    this._overlayService.updateIsLoading(true);
    this.deleteToken$()
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        this.clearAuthenticationData();
        this._isLogout = true;
        this._window.location.replace(`${ENVIRONMENT[ENV_TYPE].MARKETING_WEBSITE_URL}/logout`);
      });
  }

  public isAuthenticated(): boolean {
    return this._storageService.getItem(EStorageKeys.USER_DATA) !== null;
  }

  public getCurrentUser(): IUser {
    return JSON.parse(this._storageService.getItem(EStorageKeys.USER_DATA) || '{}');
  }

  public getCompanyId(): string {
    const userData = this.getCurrentUser();

    return userData.company?.id || '';
  }

  public deleteToken$(): Observable<void> {
    return this._httpService.httpClient
      .delete<void>(`${SERVER_URL}/auth/v2/token`, {
        observe: 'body',
      })
      .pipe(map((response: void): void => response));
  }
}
