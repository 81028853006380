export enum EAvailablePath {
  ACCOUNT = '/account',
  ANALYTICS = '/analytics',
  CHECKLISTS = '/checklists',
  HOME = '/home',
  INTERACTIVE_REPORT = '/interactive-report',
  NETWORK = '/network',
  PAYMENTS = '/payments',
  PRODUCE_REPORTS = '/produce-reports',
  REPORTS = '/reports',
  SUPPLIER_DOCUMENTS = '/supplier-documents',
  SUPPLIERS = '/suppliers',
}
